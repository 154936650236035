/* eslint-disable max-len */
import { Notifications } from 'lib/features/alerts/types';
import {
  Views,
  ListAlert,
} from './types';

export const listAlerts: ListAlert[] = [
  {
    id: Notifications.FEBRUARY_LOST_ORDERS,
    text: 'Feb 7, 2025. AI Marketplace is about to commence the move from Polygon testnet to the mainnet blockchain, which will be used going forward. Because it’s a different blockchain, all order history will be lost and active orders will be canceled. This is a major change and will require longer downtime: from Feb 11th until Feb 14th.',
    view: [Views.desktop],
  },
  // {
  //   id: Notifications.NODE_CRASH,
  //   text: 'The Polygon testnet node went through a hardfork and is now offline. We will need to assign a new node. Order creation and management will be unavailable until this warning disappears.',
  //   view: [Views.desktop],
  // },
  // {
  //   id: Notifications.USE_DESKTOP,
  //   text: 'Mobile version of Marketplace is view-only. Please use desktop for full functionality.',
  //   view: [Views.mobile],
  // },
];

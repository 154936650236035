import { Mode } from 'uikit/NotificationLine/types';
import { PartiallyRequired } from 'types/types';

export enum Notifications {
  USE_DESKTOP = 'USE_DESKTOP',
  REPLENISH_ORDERS = 'REPLENISH_ORDERS',
  CHANGE_NODE = 'CHANGE_NODE',
  NODE_CRASH = 'NODE_CRASH',
  FEBRUARY_LOST_ORDERS = 'FEBRUARY_LOST_ORDERS'
}

export type Alert = {
  id: Notifications;
  text: string;
  mode: Mode;
}

export interface AlertsState {
  alerts: Alert[];
  alertsChecker: { [id: string]: any }; // save alert information to prevent the alert from opening
}

export type AddAlertProps = PartiallyRequired<Partial<Alert>, 'id'>;
